<template>
  <div class="createPost-container">
    <el-form
      ref="form"
      :model="form"
      :rules="getRules(fields)"
      class="form-container"
      :label-width="formLabelWidth"
      :label-position="formLabelPosition"
    >
      <sticky v-if="$slots.default || saveButtonPosition === 'top'" :class-name="'sub-navbar '">
        <editor-button
          v-if="showDelete"
          style="margin-right:15px"
          :loading="actionLoading"
          action="delete"
          @click="handleDelete"
        />
        <slot name="page-buttons" v-bind="{ loading: actionLoading }" />
        <slot>
          <editor-button v-if="showSave" :loading="actionLoading" action="save" @click="handleSave" />
          <editor-button v-if="showSaveAndClose" :loading="actionLoading" action="saveClose" @click="saveAndClose" />
          <editor-button v-if="showSaveAndNext" :loading="actionLoading" action="saveNext" @click="saveAndNext" />
          <editor-button :loading="actionLoading" action="cancel" @click="close" />
        </slot>
      </sticky>
      <div v-loading="pageLoading" class="createPost-main-container">
        <el-alert v-if="pageMessage" :title="pageMessage" type="info" :closable="false" />
        <el-row :gutter="20">
          <el-col
            v-for="(columnFields, columnIndex) in columnsFields"
            :key="columnIndex"
            :xs="24"
            :lg="getWidthFromPercentage(columnsWidthPercentages[columnIndex], columnsFields)"
          >
            <el-row v-for="(spec, name, index) in columnFields" v-show="getVisibleProp(spec) !== false" :key="name">
              <el-col v-if="isEdit || !spec.hideOnCreate">
                <el-form-item :prop="name" :label="getLabel(spec, name)">
                  <template v-if="getInfoTooltip(spec)" v-slot:label>
                    {{ getLabel(spec, name) }}
                    <el-tooltip
                      class="infoToolTip"
                      effect="dark"
                      :content="getInfoTooltip(spec)"
                      placement="top-right"
                      icon="el-icon-info"
                    >
                      <span class="el-icon-info" />
                    </el-tooltip>
                  </template>
                  <component
                    :is="spec.type"
                    :ref="createRef(columnIndex, index)"
                    v-model="form[name]"
                    v-bind="getProps(spec)"
                    :disabled="getDisabledProp(spec)"
                    :name="name"
                    :initial-value="formData[name]"
                    :editor-number="editorNumber"
                    @cleaner="registerCleaner(name, $event)"
                    @presave="registerPreSaveHook(name, $event)"
                    @postsave="registerPostSaveHook(name, $event)"
                    @requesterror="registerRequestErrorHook(name, $event)"
                    @init="
                      initialVal => {
                        handleInit(name, initialVal);
                      }
                    "
                    @input="clearInputErrorFor(name, spec.showErrorsFromFields)"
                  >
                  </component>
                  <input-errors :errors="getInputErrors(errors, name, spec, form)" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <sticky v-if="$slots.footer || saveButtonPosition === 'bottom'" :class-name="'sub-navbar '">
        <slot name="footer">
          <editor-button
            v-if="!innerDisabled"
            :icon-only="false"
            :loading="actionLoading"
            action="save"
            @click="handleSave"
          />
        </slot>
      </sticky>
    </el-form>
  </div>
</template>

<script>
import Sticky from '@/components/Sticky'; // Sticky header-component
import InputErrors from '@/components/DSE/InputErrors';
import editorMixin from './mixins/editor-mixin';
import EditorButton from './EditorButton';

export default {
  components: {
    Sticky,
    EditorButton,
    InputErrors
  },
  mixins: [editorMixin],
  props: {
    formLabelWidth: {
      type: String,
      default: '150px'
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import '~@/styles/mixin.scss';

.createPost-container {
  position: relative;

  .createPost-main-container {
    padding: 40px 45px 20px 50px;

    .read-only-message {
      margin-bottom: 1.5em;
    }
    .postInfo-container {
      position: relative;
      @include clearfix;
      margin-bottom: 10px;

      .postInfo-container-item {
        float: left;
      }
    }
  }

  .word-counter {
    width: 40px;
    position: absolute;
    right: -10px;
    top: 0px;
  }
}
.el-dialog {
  .el-dialog__body {
    .createPost-container {
      .createPost-main-container {
        padding: 0px;
      }
    }
  }
}

.input-error {
  display: block;
  color: #f56c6c;
  padding-top: 4px;
  position: relative;
  font-size: 12px;
  line-height: 16px;
}
</style>
