<template>
  <div class="select-container">
    <span class="material-input-bar" />
    <label class="material-label">
      <slot />
    </label>
    <br v-if="$slots['default']" />
    <div v-loading="loading" class="el-select-wrapper">
      <el-tooltip
        v-if="!readonly"
        :disabled="!tooltip"
        class="item"
        effect="dark"
        :content="tooltip || ''"
        placement="top-start"
      >
        <el-select
          ref="elementSelect"
          v-model="currentValue"
          :placeholder="fillPlaceHolder"
          :name="name"
          :multiple="multiple"
          :collapse-tags="collapseTags"
          :readonly="readonly"
          :disabled="disabled"
          :required="required"
          :default-first-option="defaultFirstOption"
          :filterable="filterable"
          @input="handleModelInput"
          @focus="handleFocus"
        >
          <el-option
            v-for="item in selectOptions"
            :key="item[valueField]"
            :label="item[labelField]"
            :value="item[valueField]"
            :disabled="optionDisabled ? optionDisabled(item) : false"
          />
        </el-select>
      </el-tooltip>
      <md-input v-if="readonly" v-model="readOnlyValue" :readonly="true" type="text" />
      <span v-if="!readonly" v-show="showClose" class="clear-select">
        <i class="el-select__caret el-input__icon el-icon-circle-close" @click="handleClear"></i>
      </span>
    </div>
  </div>
</template>

<script>
import MdInput from '../MDinput';
export default {
  components: { MdInput },
  props: {
    collapseTags: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    },
    labelField: {
      default: 'value',
      type: String
    },
    name: String,
    defaultFirstOption: {
      default: false,
      type: Boolean
    },
    placeholder: {
      default: '',
      type: String
    },
    multiple: {
      default: false,
      type: Boolean
    },
    value: [String, Number, Array, Boolean],
    options: [Array, Function], // If function is given, currentValue is passed as parameter
    readonly: {
      default: false,
      type: Boolean
    },
    required: {
      default: false,
      type: Boolean
    },
    valueField: {
      default: 'id',
      type: String
    },
    filterable: {
      default: true,
      type: Boolean
    },
    loading: {
      default: false,
      type: Boolean
    },
    selectFirstOnDefault: {
      default: false,
      type: Boolean
    },
    translateLabels: Boolean,
    optionDisabled: Function,
    tooltip: [Boolean, String]
  },
  data() {
    return {
      currentValue: this.value,
      onOptionsLoaded: []
    };
  },
  computed: {
    fillPlaceHolder() {
      return this.currentValue ? '' : this.placeholder;
    },
    selectOptions() {
      if (typeof this.options === 'function') {
        return this.options(this.currentValue);
      }
      if (this.translateLabels) {
        return this.options.map(option => {
          const translatedOption = Object.assign({}, option);
          translatedOption[this.labelField] = this.$i18n.t(option[this.labelField]);
          return translatedOption;
        });
      }
      return this.options;
    },
    readOnlyValue() {
      const currentOption = this.selectOptions.find(option => option[this.valueField] === this.currentValue);
      return currentOption && currentOption[this.labelField];
    },
    clearable() {
      return !this.required && !this.multiple;
    },
    showClose() {
      return this.clearable && !this.valueIsEmpty() && !this.disabled;
    }
  },
  watch: {
    value(newValue) {
      if (newValue === '' && this.selectFirstOnDefault && this.selectOptions.length > 0) {
        this.handleModelInput(this.selectOptions[0][this.valueField]);
      } else {
        this.currentValue = newValue;
      }
    },
    selectOptions(selectOptions) {
      if (this.value === '' && this.selectFirstOnDefault && selectOptions.length > 0) {
        this.handleModelInput(selectOptions[0][this.valueField]);
      }
      if (selectOptions.length) {
        this.performOnOptionsLoaded();
      }
    }
  },
  methods: {
    performOnOptionsLoaded() {
      let hook;
      while ((hook = this.onOptionsLoaded.shift())) {
        hook();
      }
    },
    handleClear() {
      this.$emit('input', null);
    },
    valueIsEmpty() {
      return (
        this.value === '' ||
        this.value === null ||
        this.value === undefined ||
        (Array.isArray(this.value) && !this.value.length)
      );
    },
    focus() {
      this.handleFocus();
    },
    setSoftFocus() {
      const select = this.$refs.elementSelect;
      select.blur();
      this.$nextTick(() => {
        if (!this.selectOptions.length) {
          // If softFocus is performed, and the value is loaded before the options are,
          // then the value is shown instead of the label,
          // therefore do setSoftFocus after options are loaded
          return this.onOptionsLoaded.push(this.setSoftFocus);
        }
        select.setSoftFocus();
      });
    },
    hasNoPreviousFocusTarget(event) {
      return event && !event.relatedTarget;
    },
    isMultipleSelectClickFocus(event) {
      return this.multiple && event && this.$el.contains(event.relatedTarget);
    },
    handleFocus(event) {
      const select = this.$refs.elementSelect;
      if (!select) {
        return;
      }
      if (this.valueIsEmpty() || this.hasNoPreviousFocusTarget(event) || this.isMultipleSelectClickFocus(event)) {
        select.focus();
      } else {
        this.setSoftFocus();
      }
    },
    handleModelInput(value) {
      this.$emit('input', value);
      this.$emit('change', value);
    }
  }
};
</script>
<style scoped>
.material-label {
  color: #9e9e9e;
}
.el-select {
  width: 100%;
}
</style>
<style lang="scss" scoped>
.el-select-wrapper {
  width: 100%;
  max-width: 300px;
  display: inline-block;
  .clear-select {
    width: 0px;
    height: 0px;
    float: right;
    display: none;
    position: relative;
    right: 50px;
    top: -2px;
    &:hover {
      cursor: pointer;
    }
  }
  &:hover .clear-select {
    display: initial;
  }
}
</style>
