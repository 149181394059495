<template>
  <div>
    <el-button :disabled="disabled" :icon="icon" :type="type" @click="handleClick"> {{ $t(buttonText) }}</el-button>
  </div>
</template>

<script>
export default {
  props: {
    onclick: Function,
    buttonText: String,
    type: {
      type: String,
      default: 'primary'
    },
    icon: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick() {
      if (this.onclick) {
        this.onclick();
      }
    }
  }
};
</script>
